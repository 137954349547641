<template>
    <div class="chart-view">
        <ChartBox 
        :name="props.name" 
        :loading="props.loading" 
        @mounted="renderChart"
        @resize="onViewResize"
        >
        <template #header>
            <n-popover trigger="click" placement="bottom-end" raw>
                <template #trigger>
                    <n-button
                        size="tiny"
                        style="margin-top: 5px"
                        quaternary
                        class="more"
                    >
                        <template #icon>
                            <NIcon size="small">
                                <MoreHorizFilled />
                            </NIcon>
                        </template>
                    </n-button>
                </template>
                <div style="background-color: #fff; padding: 2px">
                    <n-button
                        size="tiny"
                        quaternary
                        block
                        @click="exportCSV"
                    >
                        导出CSV
                    </n-button>
                    <n-button
                        size="tiny"
                        @click="showAlarmCreator"
                        quaternary
                        block
                    >
                        创建监控
                    </n-button>
                </div>
            </n-popover>
        </template>
    </ChartBox>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.chart-view {
    height: 25vw;
    .standard-border;
}
</style>
<script setup>
import { defineProps, watch,defineEmits,h, ref } from 'vue';
import ChartBox from '@/components/ChartBox';
import { NButton, NPopover, NIcon } from 'naive-ui';
import { MoreHorizFilled } from '@vicons/material';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { AdvancedTooltip } from '@/common/EChartsTools';
import DefaultTooltipBox from './Tooltip.vue';
import { formatNumber } from '@/common/tools';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    MarkPointComponent,
    TitleComponent,
} from 'echarts/components';

echarts.use([GridComponent, LegendComponent, TooltipComponent, MarkPointComponent,LineChart, CanvasRenderer,TitleComponent]);

const emits = defineEmits(['openDrawer', 'updateDimension', 'showAlarmCreator']);

const props = defineProps({
    name: {
        type: String,
    },
    chartData: {
        type: Object,
        default() {
            return {};
        },
    },
    loading: {
        type: Boolean,
        default: true,
    },
    dimension: {
        type: String,
    },
});

let echartInstance = null;
let aTooltip = new AdvancedTooltip();

let calTotal = ref([]);

let csvContent = ref('');

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

function onDrillDown(name) {
    const val = {
        dimension: props.dimension,
        name: name,
    }
    emits('updateDimension', val);
    // router.push({
    //     params: {
    //         name: `${route.params.name}>${nodeName}`,
    //     },
    //     query: route.query,
    // });
    // Log.click('cost_view_drill_down');
}

function showAlarmCreator() {
    const val = {
        dimension: props.dimension,
        name: props.name,
    }
    emits('showAlarmCreator', val);
};

function exportCSV() {
    let blob = new Blob([csvContent.value], { type: "text/csv;charset=utf-8;" });

    let link = document.createElement("a");
    if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        alert("Your browser does not support downloading files.");
    }
};

function handleCSVData(column, origin) {
    csvContent.value = "";
    let csvData = [];
    csvContent.value += "name";
    for (let k in column) {
        csvContent.value += "," + column[k];
    }
    csvContent.value += ",Total,Percentage\n";
    let total = 0.0
    for (const item in origin) {
        if (item !== 'query') {
            for (const d in origin[item]) {
                total += parseFloat(origin[item][d][0].toFixed(2))
            }
        }
    }
    for (const item in origin) {
        let temp = []
        let sum = 0.0
        if (item !== 'query') {
            temp.push(item)
            if (Object.keys(origin[item]).length !== column.length) {
                if (Object.keys(origin[item]).length === 0) {
                    for (let k in column) {
                        temp.push('/')
                    }
                } else {
                    for (let k in column) {
                        if (origin[item][column[k]] !== undefined) {
                            temp.push(origin[item][column[k]][0])
                            sum += parseFloat(origin[item][column[k]][0].toFixed(2))
                        } else {
                            temp.push('/')
                        }
                    }
                }
            } else {
                for (const d in origin[item]) {
                    temp.push(origin[item][d][0])
                    sum += parseFloat(origin[item][d][0].toFixed(2))
                }
            }
        }
        let percent = '/'
        percent = total === 0.0 ? '/' : ((sum/total)*100).toFixed(2)+'%'
        temp.push(sum)
        temp.push(percent)

        csvData.push(temp)
    }

    csvData.forEach(function(row) {
        csvContent.value += row.join(",") + "\n";
    });
};

function open(seriesIndex, dataIndex) {
    if (dataIndex === 0) {
        return;
    }

    const cur_val = {
        name: options.series[seriesIndex].name+'-'+props.name,
        value: options.series[seriesIndex].data[dataIndex],
        date: options.xAxis.data[dataIndex]
    }
    const yoy_val = {
        name: options.series[seriesIndex].name+'-'+props.name,
        value: calTotal.value[seriesIndex].yoyList[dataIndex],
        date: options.xAxis.data[dataIndex] //同比date未知
    }
    const pre_val = {}
    const currentIndex = options.xAxis.data.indexOf(cur_val.date)
    if (currentIndex > 0) {
        for (let k in options.series) {
            if (options.series[k].name === cur_val.name.split('-')[0]) {
                pre_val.value = options.series[k].data[currentIndex-1]
            }
        }
        pre_val.date = options.xAxis.data[currentIndex-1]
        pre_val.name = cur_val.name
    }

    const index = options.xAxis.data.indexOf(cur_val.date)
    let dateList = []
    if (index !== -1) {
        dateList = options.xAxis.data.slice(0, index+1)
    }
    const val = {
        cur: cur_val,
        pre: pre_val,
        yoy: yoy_val,
        valueList: options.series[seriesIndex].data.slice(0, index+1),
        dateList: dateList,
    }    
    emits('openDrawer', val);
}

let options = {
    legend: {
        show: true,
        type: 'scroll',
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [],
}

watch(() => props.chartData,
     (newVal, oldVal) => {
        if (newVal !== oldVal) {
            echartInstance.clear();
            options.series.splice(0, options.series.length);
            let axis = []
            // let calTotal = []
            calTotal.value = []
            for (let key in props.chartData) {
                if (key !== 'query') {
                    if (props.chartData[key] === null || props.chartData[key] === undefined) continue
                    if (Object.keys(props.chartData[key]).length === 0) continue
                    let temp = [] //处理当前数据
                    let yoyTemp = [] //处理同比数据
                    let sum = 0.0 //Dashboard页面Table的汇总数据
                    for (let val in props.chartData[key]) {
                        axis.push(val)
                        if (props.chartData[key][val][0] !== null) {
                            temp.push(parseFloat(props.chartData[key][val][0].toFixed(2)))
                            sum += parseFloat(props.chartData[key][val][0].toFixed(2))
                        } else {
                            temp.push(null)
                        }

                        if (props.chartData[key][val][1] !== -1) {
                            yoyTemp.push(parseFloat(props.chartData[key][val][1].toFixed(2)))
                        } else {
                            yoyTemp.push(-1)
                        }
                    }
                    let markerList = []
                    for (let k=0;k<temp.length-1;k++) {
                        let value = temp[k+1] - temp[k]
                        let rate = parseFloat(((temp[k+1] - temp[k]) / temp[k]).toFixed(2))
                        markerList.push({
                            // value: parseFloat(value.toFixed(2)),
                            value: rate,
                            coord: [k+1, temp[k+1]],
                            itemStyle: {
                                color: value >=0 ? '#fec8c9' : '#ABD0CE'
                            }
                        })
                    }
                    calTotal.value.push({
                        name: key,
                        value: sum,
                        list: temp,
                        yoyList: yoyTemp,
                        markerList: markerList,
                    })
                }
            }
            options.xAxis.data = [...new Set(axis)];
            calTotal.value.sort((a, b) => b.value - a.value)

            // 这里原本想展示Top5数据，现全部放开
            // if (calTotal.length > 5) {
            //     calTotal = calTotal.slice(0, 5)
            // }
            for (let k in calTotal.value) {
                options.series.push({
                    data: calTotal.value[k].list,
                    type: 'line',
                    name:  calTotal.value[k].name,
                    emphasis: { focus: 'series' },
                    connectNulls: true,
                    // markPoint: {
                    //     label: {
                    //         show: true,
                    //         color: '#000',
                    //         formatter: function(params) {
                    //             return params.value + '%';
                    //         },
                    //     },
                    //     symbol: 'pin',
                    //     symbolSize: 40,
                    //     data: calTotal[k].markerList,
                    // },
                })
            }
            options.title = {
                show: options.series.length === 0,
                textStyle: {
                    color: "black",
                    fontSize: 16
                },
                text: "No data",
                left: "center",
                top: "center"
            };
            options.tooltip = aTooltip.tooltip({
                    valueFormatter: value => formatNumber(value),
                    vNodeCreator:
                            (params, option) =>
                                  h(
                                      DefaultTooltipBox,
                                      {
                                          data: params,
                                          option,
                                      },
                                      {
                                          itemSuffix: slotProps => (
                                            <span>
                                                <NButton
                                                    size="tiny"
                                                    quaternary
                                                    color="#000"
                                                    style="margin-right: 4px; width: 70px; cursor: default;"
                                                >
                                                环比 {slotProps.series.dataIndex === 0 ? '/' :  (((parseFloat(slotProps.series.value)-parseFloat(calTotal.value[slotProps.series.seriesIndex].list[slotProps.series.dataIndex-1])) / parseFloat(calTotal.value[slotProps.series.seriesIndex].list[slotProps.series.dataIndex-1]))*100).toFixed(2)+'%'}
                                                </NButton>
                                                <NButton
                                                    size="tiny"
                                                    quaternary
                                                    color="#000"
                                                    style="margin-right: 4px; width: 70px; cursor: default;"
                                                >
                                                同比 {calTotal.value[slotProps.series.seriesIndex].yoyList[slotProps.series.dataIndex] === -1 ? '/' : (((parseFloat(slotProps.series.value)-parseFloat(calTotal.value[slotProps.series.seriesIndex].yoyList[slotProps.series.dataIndex])) / parseFloat(calTotal.value[slotProps.series.seriesIndex].yoyList[slotProps.series.dataIndex]))*100).toFixed(2)+'%'}
                                                </NButton>
                                                { props.dimension === 'all' ? null : <NButton
                                                    size="tiny"
                                                    ghost
                                                    color="#0B6EE2"
                                                    style="margin-left: 8px"
                                                    onClick={() =>
                                                        onDrillDown(slotProps.series.seriesName)
                                                    }
                                                >
                                                    下钻
                                                </NButton>
                                                }
                                                { slotProps.series.dataIndex === 0 ? null : <NButton size="tiny" ghost color="#3f51b5"
                                                    style="margin-left: 8px"
                                                    onClick={() => open(slotProps.series.seriesIndex, slotProps.series.dataIndex)}>详情</NButton>
                                                }
                                            </span>
                                          ),
                                      }
                                  ),
                    maxHeight: 200,
                    opt: {
                        position: (point, params, dom, rect, size) => {
                            let [x, y] = point;
                            let [cWidth, cHeight] = size.contentSize;
                            let positionX = x < 400 ? x + 10 : x - cWidth - 10;
                            let positionY = y < 70 ? y + 10 : y - cHeight - 5;
                            return [
                                positionX,
                                positionY > -100 ? positionY : -100,
                            ];
                        },
                    },
                }),
            echartInstance.setOption(options)
            echartInstance.on('click', function (params) {
                if (params.seriesType === 'line') {

                    open(params.seriesIndex, params.dataIndex)

                    // const dataIndex = params.dataIndex;
                    // if (dataIndex === 0) {
                    //     return;
                    // }
                    // const seriesIndex = params.seriesIndex;

                    // const cur_val = {
                    //     name: options.series[seriesIndex].name+'-'+props.name,
                    //     value: options.series[seriesIndex].data[dataIndex],
                    //     date: options.xAxis.data[dataIndex]
                    // }
                    // const yoy_val = {
                    //     name: options.series[seriesIndex].name+'-'+props.name,
                    //     value: calTotal[seriesIndex].yoyList[dataIndex],
                    //     date: options.xAxis.data[dataIndex] //同比date未知
                    // }
                    // const pre_val = {}
                    // const currentIndex = options.xAxis.data.indexOf(cur_val.date)
                    // if (currentIndex > 0) {
                    //     for (let k in options.series) {
                    //         if (options.series[k].name === cur_val.name.split('-')[0]) {
                    //             pre_val.value = options.series[k].data[currentIndex-1]
                    //         }
                    //     }
                    //     pre_val.date = options.xAxis.data[currentIndex-1]
                    //     pre_val.name = cur_val.name
                    // }

                    // const index = options.xAxis.data.indexOf(cur_val.date)
                    // let dateList = []
                    // if (index !== -1) {
                    //     dateList = options.xAxis.data.slice(0, index+1)
                    // }
                    // const val = {
                    //     cur: cur_val,
                    //     pre: pre_val,
                    //     yoy: yoy_val,
                    //     valueList: options.series[seriesIndex].data.slice(0, index+1),
                    //     dateList: dateList,
                    // }    
                    // emits('openDrawer', val);
                }
                // if (params.componentType === 'markPoint') {
                //     console.log(options.series[params.seriesIndex].name)
                //     console.log(options.series[params.seriesIndex].data[params.data.coord[0]])
                //     console.log(options.xAxis.data[params.data.coord[0]])


                //     const cur_val = {
                //         name: options.series[params.seriesIndex].name+'-'+props.name,
                //         value: options.series[params.seriesIndex].data[params.data.coord[0]],
                //         date: options.xAxis.data[params.data.coord[0]]
                //     }
                //     const pre_val = {}
                //     const currentIndex = options.xAxis.data.indexOf(cur_val.date)
                //     if (currentIndex > 0) {
                //         for (let k in options.series) {
                //             if (options.series[k].name === cur_val.name.split('-')[0]) {
                //                 pre_val.value = options.series[k].data[currentIndex-1]
                //             }
                //         }
                //         pre_val.date = options.xAxis.data[currentIndex-1]
                //         pre_val.name = cur_val.name
                //     }
                //     const val = {
                //         cur: cur_val,
                //         pre: pre_val,
                //         type: t.value
                //     }                    
                //     emits('openDrawer', val);
                // }
            });

            // For Export
            handleCSVData(options.xAxis.data, props.chartData);
        }
});
</script>
