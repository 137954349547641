<template>
    <div class="chart-view">
        <ChartBox 
        :name="props.name" 
        :loading="props.loading" 
        @mounted="renderChart"
        @resize="onViewResize"
        >
            <template #header>
                <n-button
                    style="margin-top: 5px"
                    size="tiny"
                    :disabled="tableData.length === 0"
                    @click="showTable = true"
                    >占比详情</n-button
                >
            </template>
        </ChartBox>
        <n-modal v-model:show="showTable">
            <n-card style="width: 600px">
                <n-data-table
                size="small"
                :columns="columns"
                :data="tableData"
                :bordered="false"
                :max-height="400"
                :loading="props.loading">
                </n-data-table>
            </n-card>
        </n-modal>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.chart-view {
    .standard-border;
    height: 25vw;
}
</style>
<script setup>
import { ref, defineProps, watch, h } from 'vue';
import ChartBox from '@/components/ChartBox';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
    TitleComponent
} from 'echarts/components';
echarts.use([GridComponent, LegendComponent, TooltipComponent,TitleComponent,PieChart, CanvasRenderer]);
import {
    NButton,
    NModal,
    NCard,
    NDataTable,
    NTag,
} from 'naive-ui';

const props = defineProps({
    name: {
        type: String,
    },
    chartData: {
        type: Object,
        default() {
            return {};
        },
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

let tableData = ref([]);

let columns = ref([
    {
        title: '成本项',
        key: 'name',
        align: 'center',
    },
    {
        title: '成本值(Total)',
        key: 'cost',
        // width: 100,
        align: 'center',
        render (row) {
            if (row.render) {
                return h(
                    NTag,
                    {
                        type: 'error',
                        size: 'small',
                        style: {
                            fontWeight: 'bold'
                        }
                    },
                    {
                        default: () => row.cost
                    }
                )
            } else {
                return row.cost
            }
      }
    },
    {
        title: '成本占比',
        key: 'rate',
        align: 'center',
    },
]);

let echartInstance = null;

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

let showTable = ref(false);

let options = {
    legend: {
        show: false,
    },
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
    series: []
}

function handleTable() {
    tableData.value.splice(0, tableData.value.length)
    let calTotal = []
    let allsum = 0.0
    for (let key in props.chartData) {
        if (key !== 'query') {
            for (let val in props.chartData[key]) {
                if (props.chartData[key][val][0] !== null) {
                    allsum += parseFloat(props.chartData[key][val][0].toFixed(2))
                }
            }
        }
    }
    for (let key in props.chartData) {
        if (key !== 'query') {
            let sum = 0.0
            for (let val in props.chartData[key]) {
                if (props.chartData[key][val][0] !== null) {
                    sum += parseFloat(props.chartData[key][val][0].toFixed(2))
                }
            }
            calTotal.push({
                name: key,
                value: sum,
                rate: ((sum / allsum) * 100).toFixed(2) + '%'
            })
        }
    }
    calTotal.sort((a, b) => b.value - a.value)
    
    if (calTotal.length > 3) {
        for (let k in calTotal) {
            tableData.value.push({
                name: calTotal[k].name,
                cost: parseFloat((calTotal[k].value).toFixed(2)),
                rate: calTotal[k].rate,
                render: k < 3 ? true : false
            })
        }
    } else {
        for (let k in calTotal) {
            tableData.value.push({
                name: calTotal[k].name,
                cost: parseFloat((calTotal[k].value).toFixed(2)),
                rate: calTotal[k].rate,
                render: false
            })
        }
    }
}

watch(() => props.chartData,
     (newVal, oldVal) => {
        if (newVal !== oldVal) {
            echartInstance.clear();
            options.series.splice(0, options.series.length);
            let pieData = []
            for (let key in props.chartData) {
                if (key !== 'query') {
                    if (Object.keys(props.chartData[key]).length === 0) continue
                    let temp = []
                    for (let val in props.chartData[key]) {
                        if (props.chartData[key][val][0] !== null) {
                            temp.push(parseFloat(props.chartData[key][val][0].toFixed(2)))
                        }
                    }
                    const sum = temp.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                    pieData.push({
                        name: key,
                        value: sum.toFixed(2),
                    })
                }
            }
            options.series[0] = {
                data: pieData,
                type: 'pie',
                name: '成本分摊',
                radius: '58%',
            }
            options.title = {
                show: options.series.length === 0,
                textStyle: {
                    color: "black",
                    fontSize: 16
                },
                text: "No data",
                left: "center",
                top: "center"
            };
            echartInstance.setOption(options)
            handleTable();
        }
});
</script>