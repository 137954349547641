<template>
    <div class="analysis-view">
        <div class="fixed-controller">
            <ToolBar
                :metadata="metadata"
                :updateDimension="updateDimension"
                @update:date="updateDate"
                @update:granularity="updateGranularity"
                @update:costType="updateCostType"
                @update:cloud="updateCloud"
                @update:region="updateRegion"
                @update:system="updateSystem"
                @update:adx="updateAdx"
                @update:dsp="updateDsp"
                @update:platform="updatePlatform"
                @update:biddingType="updateBiddingType"
                @onQuery="onQuery"
            ></ToolBar>
        </div>
        <div class="content">
            <all
                :costType="costType"
                :all_data="all_data"
                :all_loading="all_loading"
                :start="dateRange[0]"
                :end="dateRange[1]"
                :reqObject="reqObject"
                @showAlarmCreator="showAlarmCreator"
            ></all>
            <div v-for="(item, idx) in dimensionList" :key="idx">
                <dimComp
                    :key="compKey"
                    :cloud="cloud"
                    :region="region"
                    :granularity="granularity"
                    :dimension="item.dim"
                    :sys="sys"
                    :adx="adx"
                    :dsp="dsp"
                    :platform="platform"
                    :biddingType="biddingType"
                    :adType="adType"
                    :start="dateRange[0]"
                    :end="dateRange[1]"
                    :costType="costType"
                    :report="report"
                    :name="item.name"
                    @loadByDimension="loadByDimension"
                    @showAlarmCreator="showAlarmCreator"
                ></dimComp>
            </div>
        </div>
        <AlarmMiniCreator
            v-model:show="showAlarmMiniBox"
            source-type="cost_madx"
            :source="alarmSource"
        />
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useMessage } from 'naive-ui';
import ToolBar from './components/ToolBar.vue';
import all from './components/Dashboard.vue';
import dayjs from 'dayjs';
import { CUSTOMIZATION_MADX_API } from '@/common/API';

import dimComp from './components/IndexComp.vue';
import AlarmMiniCreator from '@/views/Alarms/components/AlarmMiniCreator';

const route = useRoute();
const router = useRouter();

const message = useMessage();

let compKey = ref(0);
let dimensionList = ref([
    {
        dim: 'cloud',
        name: '云商',
    },
    {
        dim: 'region',
        name: '地域',
    },
    {
        dim: 'sys_type',
        name: 'Sys Type',
    },
    {
        dim: 'adx',
        name: 'ADX',
    },
    {
        dim: 'dsp',
        name: 'DSP',
    },
    {
        dim: 'platform',
        name: 'Platform',
    },
    {
        dim: 'bidding_type',
        name: 'Bidding Type',
    },
]);

let report = ref('supply');
let costType = ref(route.query.costType || 'lbcost');

let cloud = ref(route.query.cloud || 'all');
let region = ref(route.query.region || 'all');
let sys = ref(route.query.sys || 'all');
let adx = ref(route.query.adx || 'all');
let dsp = ref(route.query.dsp || 'all');
let platform = ref(route.query.platform || 'all');
let adType = ref('all');
let biddingType = ref(route.query.bidding || 'all');
let granularity = ref(route.query.granularity || 'day');
let dateRange = ref(
    route.query.start
        ? [route.query.start, route.query.end]
        : [
              dayjs().subtract(8, 'day').format('YYYY-MM-DD'),
              dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
          ]
);

let metadata = ref({});
let updateDimension = ref({});

let all_data = ref({});
let all_loading = ref(false);

let reqObject = ref({});

let showAlarmMiniBox = ref(false);
let alarmSource = ref({});

const totalCostMapping = ref({
    lbcost: 'LB成本',
    cpucost: '计算成本',
    netoutcost: '出网成本',
});

function updateCloud(value, tag) {
    cloud.value = value.cloudFilter;
    region.value = value.regionFilter;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateRegion(value, tag) {
    region.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateSystem(value, tag) {
    sys.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateAdx(value, tag) {
    adx.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateDsp(value, tag) {
    dsp.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updatePlatform(value, tag) {
    platform.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateBiddingType(value, tag) {
    biddingType.value = value;
    if (tag !== undefined) {
        load_all_cost();
        compKey.value += 1;
    }
}
function updateDate(value) {
    dateRange.value = value;
}
function updateGranularity(value) {
    granularity.value = value;
}
function updateCostType(value) {
    costType.value = value;
}

function loadByDimension(val) {
    let currentQuery = { ...router.currentRoute.value.query };
    switch (val.dimension) {
        case 'cloud':
            const temp = {
                cloudFilter: val.name,
                regionFilter: 'all',
            };
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = temp;
            currentQuery.cloud = temp.cloudFilter;
            currentQuery.region = temp.regionFilter;
            router.replace({
                query: currentQuery,
            });
            updateCloud(temp, 1);
            break;
        case 'region':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.region = val.name;
            router.replace({
                query: currentQuery,
            });
            updateRegion(val.name, 1);
            break;
        case 'sys_type':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.sys = val.name;
            router.replace({
                query: currentQuery,
            });
            updateSystem(val.name, 1);
            break;
        case 'adx':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.adx = val.name;
            router.replace({
                query: currentQuery,
            });
            updateAdx(val.name, 1);
            break;
        case 'dsp':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.dsp = val.name;
            router.replace({
                query: currentQuery,
            });
            updateDsp(val.name, 1);
            break;
        case 'platform':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.platform = val.name;
            router.replace({
                query: currentQuery,
            });
            updatePlatform(val.name, 1);
            break;
        case 'bidding_type':
            updateDimension.value['type'] = val.dimension;
            updateDimension.value['value'] = val.name;
            currentQuery.bidding = val.name;
            router.replace({
                query: currentQuery,
            });
            updateBiddingType(val.name, 1);
            break;
        default:
            break;
    }
}

function showAlarmCreator(val) {
    alarmSource.value.granularity = granularity.value;
    alarmSource.value.dimension = val.dimension;
    alarmSource.value.cloud = cloud.value;
    alarmSource.value.region = region.value;
    alarmSource.value.sys_type = sys.value;
    alarmSource.value.adx = adx.value;
    alarmSource.value.dsp = dsp.value;
    alarmSource.value.platform = platform.value;
    alarmSource.value.bidding_type = biddingType.value;
    alarmSource.value.adtype = adType.value;
    alarmSource.value.name = costType.value;
    showAlarmMiniBox.value = true;
}

function onQuery() {
    load_all_cost();
    compKey.value += 1;
}

function get_metadata() {
    CUSTOMIZATION_MADX_API.get_metadata().then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        metadata.value = res.data;
    });
}

function load_all_cost() {
    let params = {
        start_time: dateRange.value[0],
        end_time: dateRange.value[1],
        granularity: granularity.value,
        dimension: 'all',
        cloud: cloud.value,
        region: region.value,
        sys_type: sys.value,
        adx: adx.value,
        dsp: dsp.value,
        platform: platform.value,
        bidding_type: biddingType.value,
        adtype: adType.value,
    };
    reqObject.value = params;
    all_loading.value = true;
    const costTypeList = ['lbcost', 'cpucost', 'netoutcost'];
    let obj = {};
    for (const idx in costTypeList) {
        CUSTOMIZATION_MADX_API.get_cost_share(
            report.value,
            costTypeList[idx],
            params
        )
            .then(res => {
                all_loading.value = false;
                if (res.error_no !== 0) {
                    message.error(res.error_msg || '未知异常导致加载失败');
                    return;
                }
                obj[totalCostMapping.value[costTypeList[idx]]] =
                    res.data.overall;

                if (Object.keys(obj).length === 3) {
                    all_data.value = obj;
                }
            })
            .catch(error => {
                console.log(error);
                all_data.value[costTypeList[idx]] = {};
                all_loading.value = false;
            });
    }
}

onMounted(() => {
    get_metadata();
    load_all_cost();
});
</script>
<style lang="less" scoped>
@import '../../../common/common.less';
.analysis-view {
    .common-content;

    .fixed-controller {
        position: sticky;
        top: 0;
        width: 100%;
        background-color: #fff;
        z-index: 1000;
    }

    .content {
        margin-top: 10px;
        padding: 0px;
    }
}
</style>
