<template>
    <div class="chart-view">
        <ChartBox 
        :name="props.name" 
        :loading="props.loading" 
        @mounted="renderChart"
        @resize="onViewResize"
        ></ChartBox>
    </div>
</template>
<style lang="less" scoped>
.chart-view {
    height: 30vw;
    border: 1px solid #eee;
}
</style>
<script setup>
import { defineProps, watch, nextTick } from 'vue';
import ChartBox from '@/components/ChartBox';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { formatNumber } from '@/common/tools';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,
} from 'echarts/components';

echarts.use([GridComponent, LegendComponent, TooltipComponent,LineChart, CanvasRenderer,TitleComponent]);

let props = defineProps({
    name: {
        type: String,
        default: '',
    },
    loading: {
        type: Boolean,
        default: true,
    },
    data: {
        type: Object,
        default: () => {},
    },
});

let echartInstance = null;

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

let options = {
    legend: {
        show: true,
        orient: 'vertical',
        right: '0',
        height: '220',
        type: 'scroll',
    },
    grid: {
        width: '80%',
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            formatter: function (value) {
                return formatNumber(value);
            },
        },
    },
    tooltip: {
        trigger: 'axis',
        enterable: true,
        confine: true,
        borderWidth: 1,
        borderColor: '#4F86C6',
        formatter: function(params) {
            params.sort((a, b) => b.value - a.value)
            const tooltipContent = `
                <div style="max-height: 200px; overflow-y: auto;">
                    <strong style="font-size: 14px;">${params[0].axisValue}</strong>
                    ${params.map(item => `<div style="padding: 1px 0">${item.marker} ${item.seriesName}: ${formatNumber(item.value)}</div>`).join('')}
                </div>
            `;
            return tooltipContent;
        }
    },
    series: [],
};

watch(
    () => props.data,
    (newVal, oldVal) => {
        nextTick(() => {
            if (newVal !== oldVal) {
                echartInstance.clear();
                options.series.splice(0, options.series.length);
                let axis = []
                for (const key in props.data) {
                    let temp = []
                    for (const d in props.data[key]) {
                        axis.push(d)
                        if (props.data[key][d][0] !== null) {
                            temp.push(parseFloat(props.data[key][d][0].toFixed(3)))
                        } else {
                            temp.push(null)
                        }
                    }
                    options.series.push({
                        name: key,
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        data: temp
                    })
                }
                options.xAxis.data = [...new Set(axis)];
                echartInstance.setOption(options)
            }
        })
    },
    { immediate: true, deep: true }
);
</script>