<template>
    <div class="chart-view">
        <ChartBox 
        :name="props.name" 
        :loading="props.loading" 
        @mounted="renderChart"
        @resize="onViewResize"
        ></ChartBox>
    </div>
</template>
<style lang="less" scoped>
.chart-view {
    height: 30vw;
    border: 1px solid #eee;
}
</style>
<script setup>
import { computed, defineProps, watch,defineEmits,onMounted } from 'vue';
import ChartBox from '@/components/ChartBox';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    MarkPointComponent,
    TitleComponent,
} from 'echarts/components';

echarts.use([GridComponent, LegendComponent, TooltipComponent, MarkPointComponent,LineChart, CanvasRenderer,TitleComponent]);

const props = defineProps({
    name: {
        type: String,
    },
    chartData: {
        type: Array,
        default() {
            return [];
        },
    },
    dateData: {
        type: Array,
        default() {
            return [];
        },
    },
    loading: {
        type: Boolean,
        default: true,
    },
});

let echartInstance = null;

function renderChart(dom) {
    echartInstance = echarts.init(dom);
}

function onViewResize({ width }) {
    echartInstance.resize({ width });
}

let options = {
    legend: {
        show: true,
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value'
    },
    tooltip: {
        trigger: 'axis',
        borderWidth: 1,
        borderColor: '#4F86C6',
    },
    series: [],
}

onMounted(() => {
    echartInstance.clear();
    options.series.splice(0, options.series.length);
    options.xAxis.data = props.dateData
    options.series.push({
        data: props.chartData,
        type: 'line',
    })
    echartInstance.setOption(options)
});
</script>