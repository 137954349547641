<template>
    <n-drawer class="detail-n-drawer" :width="drawerWidth">
        <n-drawer-content :title="detail.name" closable>
        <template #header>
            <span class="header-label">{{ props.detail.start }} ~ {{ props.detail.end }}</span>
            <n-tag class="tag" :bordered="false" type="info">Cloud: {{ props.detail.params.cloud }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">Region: {{ props.detail.params.region }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">Sys Type: {{ props.detail.params.sys_type }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">ADX: {{ props.detail.params.adx }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">DSP: {{ props.detail.params.dsp }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">Platform: {{ props.detail.params.platform }}</n-tag>
            <n-tag class="tag" :bordered="false" type="info">Ad Type: {{ props.detail.params.adtype }}</n-tag>
        </template>
        <div class="tableContent">
            <n-data-table
            class="item"
            size="small"
            :scroll-x="700"
            :columns="lastPeriodColumns"
            :data="lastPeriodTableData"
            :loading="lastPeriodTableLoading">
            </n-data-table>
            <n-data-table
            class="item"
            size="small"
            :scroll-x="700"
            :columns="samePeriodColumns"
            :data="samePeriodTableData"
            :loading="samePeriodTableLoading">
            </n-data-table>
        </div>
        <!-- <n-data-table
        :columns="columns"
        :data="tableData"
        :loading="tableLoading"
        :bordered="false"
        >
        </n-data-table> -->
        <DetailCostTrend :chartData="costData" :dateData="dateData" name="成本趋势" :loading="costLoading"></DetailCostTrend>
        <div v-for="(item, idx) in pyroscopeOrder" :key="idx">
            <DetailMetricTrend :name="item.name" :data="item.data" :loading="item.loading"></DetailMetricTrend>
        </div>
        </n-drawer-content>
  </n-drawer>
</template>
<style lang="less" scoped>
.chart-view {
    height: 330px;
    border: 1px solid #eee;
}
.tag {
    margin-right: 10px;
}
.header-label {
    margin-right: 10px;
    font-size: 14px;
}
.detail-n-drawer {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: #fff;
}
.tableContent {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
        width: 45%;
        margin-bottom: 10px;
        flex-grow: 1;

        &:nth-child(odd) {
            margin-right: 5px;
        }

        &:nth-child(2n) {
            margin-left: 5px;
        }
    }
}
</style>
<script setup>
import { ref, defineProps,watch,nextTick } from 'vue';
import { NDrawer, NDrawerContent, NTag, NDataTable } from 'naive-ui';
import DetailCostTrend from './DetailCostTrend.vue';
import DetailMetricTrend from './DetailMetricTrend.vue';
import BigNumber from "bignumber.js";
import { CUSTOMIZATION_MADX_API } from '@/common/API';



const props = defineProps({
    detail: {
        type: Object,
        default: () => {}
    },
});

const sortedMetric = ['成本值','traffic_req','traffic_resp','dsp_req','dsp_resp','bid_resp_size','dsp_req_size','bid_rate'];
let drawerWidth = ref('90%');

//环比
let lastPeriodTableData = ref([]);
let lastPeriodColumns = ref([]);
let lastPeriodTableLoading = ref(false);

//同比
let samePeriodTableData = ref([]);
let samePeriodColumns = ref([]);
let samePeriodTableLoading = ref(false);


let tableData = ref([]);
let columns = ref([]);
let tableLoading = ref(false);

let costData = ref([]);
let dateData = ref([]);
let costLoading = ref(false);

let metricList = ref({
    'lbcost':{
        traffic_resp_times: {
            data: {},
            loading: true,
            name: 'traffic_resp'
        },
        avg_adn_adx_adxbodysize_resp: {
            data: {},
            loading: true,
            name: 'bid_resp_size'
        },
     },
    'cpucost':{
        traffic_req_times: {
            data: {},
            loading: true,
            name: 'traffic_req'
        },
        traffic_resp_times: {
            data: {},
            loading: true,
            name: 'traffic_resp'
        },
        dsp_req_times: {
            data: {},
            loading: true,
            name: 'dsp_req'
        },
        dsp_resp_times: {
            data: {},
            loading: true,
            name: 'dsp_resp'
        },
    },
    'netoutcost':{
        dsp_req_times: {
            data: {},
            loading: true,
            name: 'dsp_req'
        },
        avg_adn_adx_dspbodysize_req: {
            data: {},
            loading: true,
            name: 'dsp_req_size'
        },
    },
});

let resp_data = ref({});
let req_data = ref({});

let pyroscopeObj = ref({});
let pyroscopeOrder = ref([]);


function getCurrentCost(newVal) {
    costLoading.value = true;
    costData.value = newVal.valueList
    dateData.value = newVal.dateList
    costLoading.value = false
};

function getPyroscope(newVal) {
    let clickName = newVal.name.split('-')[0]
    let params = newVal.params
    
    if (newVal.type == 'lbcost') {
        if (newVal.dim === 'dsp') {
            metricList.value[newVal.type]['dsp_req_times'] = {
                data: {},
                loading: true,
                name: 'dsp_req'}
            if (metricList.value[newVal.type].hasOwnProperty('traffic_req_times')) {
                delete metricList.value[newVal.type]['traffic_req_times'];
            }
        } else {
            metricList.value[newVal.type]['traffic_req_times'] = {
                data: {},
                loading: true,
                name: 'traffic_req'}
            if (metricList.value[newVal.type].hasOwnProperty('dsp_req_times')) {
                delete metricList.value[newVal.type]['dsp_req_times'];
            }
        }
    }

    if (newVal.type == 'netoutcost') {
        if (newVal.dim !== 'dsp') {
            metricList.value[newVal.type]['traffic_req_times'] = {
                data: {},
                loading: true,
                name: 'traffic_req'}
        } else {
            if (metricList.value[newVal.type].hasOwnProperty('traffic_req_times')) {
                delete metricList.value[newVal.type]['traffic_req_times'];
            }
        }
    }
    
    for (const metric in metricList.value) {
        if (metric === newVal.type) {
            for (const metric_name in metricList.value[metric]) {
                tableLoading.value = true

                // 表格拆分
                lastPeriodTableLoading.value = true
                samePeriodTableLoading.value = true

                metricList.value[metric][metric_name].loading = true
                CUSTOMIZATION_MADX_API.get_origin_metric(metric_name,params).then(res => {
                    if (res.error_no !== 0) {
                        return;
                    }
                    if (metric === 'lbcost') {
                        if (metric_name === 'traffic_resp_times') {
                            resp_data.value = res.data
                        } else if (metric_name === 'dsp_req_times' || metric_name === 'traffic_req_times') {
                            req_data.value = res.data
                        }
                    }
                    metricList.value[metric][metric_name].data = res.data
                    metricList.value[metric][metric_name].loading = false

                    if (clickName === 'cpucost' || clickName === 'netoutcost' || clickName === 'lbcost') {
                        clickName = 'dimension' //这里是api数据不一致
                    }

                    if (res.data[clickName] === undefined) {
                        tableLoading.value = false

                        // 表格拆分
                        lastPeriodTableLoading.value = false
                        samePeriodTableLoading.value = false
                        return;
                    }
                    const cur = new BigNumber(res.data[clickName][props.detail.cur.date][0])
                    const pre = new BigNumber(res.data[clickName][props.detail.pre.date][0])
                    const yoy = new BigNumber(res.data[clickName][props.detail.cur.date][1])

                    // 原始全部表格数据
                    tableData.value.push({
                        name: metricList.value[metric][metric_name].name,
                        pre_data: pre.toFixed(2),
                        yoy_data: yoy.toFixed(2),
                        cur_data: cur.toFixed(2),
                        val: (cur - pre).toFixed(2),
                        rate: (((cur-pre) / pre)*100).toFixed(2) + '%',
                        yoy_val: (cur - yoy).toFixed(2),
                        yoy_rate: (((cur - yoy) / yoy)*100).toFixed(2) + '%',
                    })
                    if (tableData.value.length === Object.keys(metricList.value[metric]).length + 1) {
                        if (newVal.type == 'lbcost') {
                            const resp = tableData.value.find(item => item.name === 'traffic_resp')
                            const req = newVal.dim === 'dsp' ? tableData.value.find(item => item.name === 'dsp_req') : tableData.value.find(item => item.name === 'traffic_req')
                            tableData.value.push({
                                name: 'bid_rate',
                                pre_data: (resp.pre_data / req.pre_data).toFixed(3) + '%',
                                yoy_data: (resp.yoy_data / req.yoy_data).toFixed(3) + '%',
                                cur_data: (resp.cur_data / req.cur_data).toFixed(3) + '%',
                                val: ((resp.cur_data / req.cur_data) - (resp.pre_data / req.pre_data)).toFixed(3) + '%',
                                rate: ((((resp.cur_data / req.cur_data) - (resp.pre_data / req.pre_data)) / (resp.pre_data / req.pre_data))*100).toFixed(3) + '%',
                                yoy_val: ((resp.cur_data / req.cur_data) - (resp.yoy_data / req.yoy_data)).toFixed(3) + '%',
                                yoy_rate: ((((resp.cur_data / req.cur_data) - (resp.yoy_data / req.yoy_data)) / (resp.yoy_data / req.yoy_data))*100).toFixed(3) + '%',
                            })
                        }
                        tableLoading.value = false
                    }

                    // 环比数据表格
                    lastPeriodTableData.value.push({
                        name: metricList.value[metric][metric_name].name,
                        pre_data: pre.toFixed(2),
                        cur_data: cur.toFixed(2),
                        val: (cur - pre).toFixed(2),
                        rate: (((cur-pre) / pre)*100).toFixed(2) + '%',
                    })
                    if (lastPeriodTableData.value.length === Object.keys(metricList.value[metric]).length + 1) {
                        if (newVal.type == 'lbcost') {
                            const resp = lastPeriodTableData.value.find(item => item.name === 'traffic_resp')
                            const req = newVal.dim === 'dsp' ? lastPeriodTableData.value.find(item => item.name === 'dsp_req') : lastPeriodTableData.value.find(item => item.name === 'traffic_req')
                            lastPeriodTableData.value.push({
                                name: 'bid_rate',
                                pre_data: (resp.pre_data / req.pre_data).toFixed(3) + '%',
                                cur_data: (resp.cur_data / req.cur_data).toFixed(3) + '%',
                                val: ((resp.cur_data / req.cur_data) - (resp.pre_data / req.pre_data)).toFixed(3) + '%',
                                rate: ((((resp.cur_data / req.cur_data) - (resp.pre_data / req.pre_data)) / (resp.pre_data / req.pre_data))*100).toFixed(3) + '%',
                            })
                        }

                        lastPeriodTableData.value.sort(sortTable)
                        lastPeriodTableLoading.value = false
                    }

                    // 同比数据表格
                    samePeriodTableData.value.push({
                        name: metricList.value[metric][metric_name].name,
                        yoy_data: yoy.isEqualTo(-1) ? -1 : yoy.toFixed(2),
                        cur_data: cur.toFixed(2),
                        yoy_val: yoy.isEqualTo(-1) ? -1 : (cur - yoy).toFixed(2),
                        yoy_rate: yoy.isEqualTo(-1) ? -1 : (((cur - yoy) / yoy)*100).toFixed(2) + '%',
                    })
                    if (samePeriodTableData.value.length === Object.keys(metricList.value[metric]).length + 1) {
                        if (newVal.type == 'lbcost') {
                            const resp = samePeriodTableData.value.find(item => item.name === 'traffic_resp')
                            const req = newVal.dim === 'dsp' ? samePeriodTableData.value.find(item => item.name === 'dsp_req') : samePeriodTableData.value.find(item => item.name === 'traffic_req')
                            samePeriodTableData.value.push({
                                name: 'bid_rate',
                                yoy_data: req.yoy_data === -1 && resp.yoy_data === -1 ? -1 : (resp.yoy_data / req.yoy_data).toFixed(3) + '%',
                                cur_data: (resp.cur_data / req.cur_data).toFixed(3) + '%',
                                yoy_val: req.yoy_data === -1 && resp.yoy_data === -1 ? -1 : ((resp.cur_data / req.cur_data) - (resp.yoy_data / req.yoy_data)).toFixed(3) + '%',
                                yoy_rate: req.yoy_data === -1 && resp.yoy_data === -1 ? -1 : ((((resp.cur_data / req.cur_data) - (resp.yoy_data / req.yoy_data)) / (resp.yoy_data / req.yoy_data))*100).toFixed(3) + '%',
                            })
                        }
                        samePeriodTableData.value.sort(sortTable)
                        samePeriodTableLoading.value = false
                    }

                    pyroscopeObj.value = metricList.value[metric]
                    const temp = Object.values(pyroscopeObj.value).sort(sortTable)
                    for (const idx in temp) {
                        if (pyroscopeOrder.value.some(obj => obj.name === temp[idx].name)) {
                            continue;
                        }
                        pyroscopeOrder.value.push(temp[idx])
                    }
                    // 支持bid_rate
                    if (Object.keys(resp_data.value).length === 0 || Object.keys(req_data.value).length === 0) {
                        return;
                    }
                    if (metric === 'lbcost' && !(pyroscopeOrder.value.some(obj => obj.name === 'bid_rate'))) {
                        let bid_rate = {
                            data: {},
                            loading: false,
                            name: 'bid_rate'
                        }
                        
                        for (const item_name in req_data.value) {
                            if (resp_data.value[item_name] === undefined) {
                                continue;
                            }
                            const item_name_temp = {}
                            for (const d in req_data.value[item_name]) {
                                if (resp_data.value[item_name][d][0] === null || req_data.value[item_name][d][0] === null || req_data.value[item_name][d][0] === 0) {
                                    bid_rate['data'][item_name] = {}
                                    bid_rate['data'][item_name][d] = [null, null]
                                } else {
                                    let resp = new BigNumber(resp_data.value[item_name][d][0])
                                    let req = new BigNumber(req_data.value[item_name][d][0])
                                    bid_rate['data'][item_name] = item_name_temp
                                    item_name_temp[d] = [parseFloat((resp / req).toFixed(3)), 0]
                                }
                            }
                        }
                        pyroscopeOrder.value.push(bid_rate)
                    }

                }).catch((error) => {
                    console.log(error)
                });
            }
        }
    }
};

/**
 * Table的内容展示顺序有要求，按照const sortedMetric来排序
 */
function sortTable(a,b) {
    const aIndex = sortedMetric.indexOf(a.name);
    const bIndex = sortedMetric.indexOf(b.name);

    if (aIndex === -1) return 1;  // Not found, move to the end
    if (bIndex === -1) return -1; // Not found, move to the beginning

    return aIndex - bIndex;
};

watch(() => props.detail,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            getCurrentCost(newVal)
            getPyroscope(newVal)


            pyroscopeObj.value = metricList.value[newVal.type]

            tableData.value = [];
            columns.value = [];
            columns.value = [
                {
                    title: '指标项',
                    key: 'name',
                    align: 'center',
                },
                {
                    // title: props.detail.pre.date,
                    title: '环比值',
                    key: 'pre_data',
                    align: 'center',
                },
                {
                    title: '同比值',
                    key: 'yoy_data',
                    align: 'center',
                },
                {
                    title: '当前值',
                    key: 'cur_data',
                    align: 'center',
                },
                {
                    title: '环比差值',
                    key: 'val',
                    align: 'center',
                    render: (row) => {
                        if ((row.val).replace('%','') > 0) {
                            return <span style="color: red;">{row.val}</span>;
                        } else if ((row.val).replace('%','') < 0) {
                            return <span style="color: green;">{row.val}</span>;
                        } else {
                            return <span>{row.val}</span>;
                        }
                    },
                },
                {
                    title: '环比差值比例',
                    key: 'rate',
                    align: 'center',
                    render: (row) => {
                        if ((row.val).replace('%','') > 0) {
                            return <span style="color: red;">{row.rate}</span>;
                        } else if ((row.val).replace('%','') < 0) {
                            return <span style="color: green;">{row.rate}</span>;
                        } else {
                            return <span>{row.rate}</span>;
                        }
                    },
                },
                {
                    title: '同比差值',
                    key: 'yoy_val',
                    align: 'center',
                    render: (row) => {
                        if ((row.yoy_val).replace('%','') > 0) {
                            return <span style="color: red;">{row.yoy_val}</span>;
                        } else if ((row.yoy_val).replace('%','') < 0) {
                            return <span style="color: green;">{row.yoy_val}</span>;
                        } else {
                            return <span>{row.yoy_val}</span>;
                        }
                    },
                },
                {
                    title: '同比差值比例',
                    key: 'yoy_rate',
                    align: 'center',
                    render: (row) => {
                        if ((row.yoy_val).replace('%','') > 0) {
                            return <span style="color: red;">{row.yoy_rate}</span>;
                        } else if ((row.yoy_val).replace('%','') < 0) {
                            return <span style="color: green;">{row.yoy_rate}</span>;
                        } else {
                            return <span>{row.yoy_rate}</span>;
                        }
                    },
                },
            ];
            tableData.value.push({
                name: '成本值',
                pre_data: props.detail.pre.value,
                yoy_data: props.detail.yoy.value,
                cur_data: props.detail.cur.value,
                val: (props.detail.cur.value - props.detail.pre.value).toFixed(2),
                rate: (((props.detail.cur.value - props.detail.pre.value) / props.detail.pre.value)*100).toFixed(2) + '%',
                yoy_val: props.detail.yoy.value === -1 ? -1 : (props.detail.cur.value - props.detail.yoy.value).toFixed(2),
                yoy_rate: props.detail.yoy.value === -1 ? -1 : (((props.detail.cur.value - props.detail.yoy.value) / props.detail.yoy.value)*100).toFixed(2) + '%',
            });

            // 环比
            lastPeriodTableData.value = [];
            lastPeriodColumns.value = [];
            lastPeriodColumns.value = [
                {
                    title: '指标项',
                    key: 'name',
                    align: 'center',
                    width: 80,
                    ellipsis: {
                        tooltip: true
                    },
                    fixed: "left"
                },
                {
                    title: '环比值',
                    key: 'pre_data',
                    align: 'center',
                    width: 100,
                    ellipsis: {
                        tooltip: true
                    },
                },
                {
                    title: '当前值',
                    key: 'cur_data',
                    align: 'center',
                    width: 100,
                    ellipsis: {
                        tooltip: true
                    },
                },
                {
                    title: '环比差值',
                    key: 'val',
                    align: 'center',
                    width: 100,
                    ellipsis: {
                        tooltip: true
                    },
                    render: (row) => {
                        if ((row.val).replace('%','') > 0) {
                            return <span style="color: red;">{row.val}</span>;
                        } else if ((row.val).replace('%','') < 0) {
                            return <span style="color: green;">{row.val}</span>;
                        } else {
                            return <span>{row.val}</span>;
                        }
                    },
                },
                {
                    title: '环比差值比例',
                    key: 'rate',
                    align: 'center',
                    width: 100,
                    ellipsis: {
                        tooltip: true
                    },
                    render: (row) => {
                        if ((row.rate).replace('%','') > 0) {
                            return <span style="color: red;">{row.rate}</span>;
                        } else if ((row.rate).replace('%','') < 0) {
                            return <span style="color: green;">{row.rate}</span>;
                        } else {
                            return <span>{row.rate}</span>;
                        }
                    },
                } 
            ];
            lastPeriodTableData.value.push({
                name: '成本值',
                pre_data: props.detail.pre.value,
                cur_data: props.detail.cur.value,
                val: (props.detail.cur.value - props.detail.pre.value).toFixed(2),
                rate: (((props.detail.cur.value - props.detail.pre.value) / props.detail.pre.value)*100).toFixed(2) + '%',
            });

            // 同比
            samePeriodTableData.value = [];
            samePeriodColumns.value = [];
            samePeriodColumns.value = [
                {
                    title: '指标项',
                    key: 'name',
                    align: 'center',
                    width: 100,
                    ellipsis: {
                        tooltip: true
                    },
                    fixed: "left"
                },
                {
                    title: '同比值',
                    key: 'yoy_data',
                    align: 'center',
                    width: 120,
                    ellipsis: {
                        tooltip: true
                    },
                },
                {
                    title: '当前值',
                    key: 'cur_data',
                    align: 'center',
                    width: 120,
                    ellipsis: {
                        tooltip: true
                    },
                },
                {
                    title: '同比差值',
                    key: 'yoy_val',
                    align: 'center',
                    width: 120,
                    ellipsis: {
                        tooltip: true
                    },
                    render: (row) => {
                        if (row.yoy_val === -1) {
                            return <span>{row.yoy_val}</span>;
                        } else {
                            if ((row.yoy_val).replace('%','') > 0) {
                                return <span style="color: red;">{row.yoy_val}</span>;
                            } else if ((row.yoy_val).replace('%','') < 0) {
                                return <span style="color: green;">{row.yoy_val}</span>;
                            } else {
                                return <span>{row.yoy_val}</span>;
                            }
                        }
                    },
                },
                {
                    title: '同比差值比例',
                    key: 'yoy_rate',
                    align: 'center',
                    width: 120,
                    ellipsis: {
                        tooltip: true
                    },
                    render: (row) => {
                        if (row.yoy_val === -1) {
                            return <span>{row.yoy_rate}</span>;
                        } else {
                            if ((row.yoy_rate).replace('%','') > 0) {
                                return <span style="color: red;">{row.yoy_rate}</span>;
                            } else if ((row.yoy_rate).replace('%','') < 0) {
                                return <span style="color: green;">{row.yoy_rate}</span>;
                            } else {
                                return <span>{row.yoy_rate}</span>;
                            }
                        }
                    },
                },
            ];
            samePeriodTableData.value.push({
                name: '成本值',
                yoy_data: props.detail.yoy.value,
                cur_data: props.detail.cur.value,
                yoy_val: props.detail.yoy.value === -1 ? -1 : (props.detail.cur.value - props.detail.yoy.value).toFixed(2),
                yoy_rate: props.detail.yoy.value === -1 ? -1 : (((props.detail.cur.value - props.detail.yoy.value) / props.detail.yoy.value)*100).toFixed(2) + '%',
            });
        }
    },
);

</script>