<template>
    <div class="ctrl-bar">
        <div class="block">
            <div class="label">模块：</div>
            <n-select
                v-model:value="mtgModule"
                size="small"
                :options="mtgModuleList"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateMtgModule"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">成本项：</div>
            <n-select
                v-model:value="costType"
                size="small"
                :options="mtgModule === 'madx' ? costTypeListMadx : costTypeListSSP"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateCostType"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">日期：</div>
            <f-date-picker
                    class="input"
                    style="width: 120px"
                    v-model:date="dateRes"
                    :is-date-disabled="disableDate"
                    @update:date="onQueryChangeDate"
            ></f-date-picker>
        </div>
        <div class="block">
            <div class="label">粒度：</div>
            <n-radio-group
                v-model:value="granularity"
                name="granularity"
                size="small"
                class="input"
                @update:value="onQueryChangeGranularity"
            >
                <n-radio-button label="日" value="day"></n-radio-button>
                <n-radio-button label="周" value="week"></n-radio-button>
                <n-radio-button label="相对周" value="7"></n-radio-button>
                <n-radio-button label="月" value="month"></n-radio-button>
            </n-radio-group>
        </div>
        <div class="block">
            <div class="label">云商：</div>
            <n-select
                v-model:value="cloudFilter"
                size="small"
                :options="cloudList"
                filterable
                tag
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateCloudFilter"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">地域：</div>
            <n-select
                v-model:value="regionFilter"
                size="small"
                :options="regionList"
                filterable
                tag
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateRegionFilter"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">流量类型：</div>
            <n-select
                v-model:value="sysFilter"
                size="small"
                :options="sysList"
                :filterable="true"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateSysFilter"
            ></n-select>
        </div>
        <div class="block" v-if="mtgModule === 'madx'">
            <div class="label">ADX：</div>
            <n-select
                v-model:value="adxFilter"
                size="small"
                :options="adxList"
                filterable
                tag
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateAdxFilter"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">DSP：</div>
            <n-select
                v-model:value="dspFilter"
                size="small"
                :options="dspList"
                filterable
                tag
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateDspFilter"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">Platform：</div>
            <n-select
                v-model:value="platFilter"
                size="small"
                :options="platformList"
                :filterable="true"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updatePlatFilter"
            ></n-select>
        </div>
        <div class="block">
            <div class="label">BiddingType：</div>
            <n-select
                v-model:value="biddingTypeFilter"
                size="small"
                :options="biddingTypeList"
                :filterable="true"
                style="width: 120px"
                class="input"
                :consistent-menu-width="false"
                @update:value="updateBiddingFilter"
            ></n-select>
        </div>
        <n-button
            size="small"
            class="input"
            secondary
            type="primary"
            @click="onQueryResult"
        >
            <template #icon>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
            查询
        </n-button>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.ctrl-bar {
    .common-ctrl-bar;
}
</style>

<script setup>
import { ref,defineEmits,defineProps, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NSelect, NRadioButton, NRadioGroup, NButton, NIcon } from 'naive-ui';
import { SearchFilled } from '@vicons/material';

import FDatePicker from '@/components/FinOpsDatePicker';
import dayjs from 'dayjs';

const route = useRoute()
const router = useRouter()

const emits = defineEmits(['update:date', 'update:granularity', 'update:mtgModule','update:costType', 'update:cloud', 'update:region', 'update:system', 'update:adx','update:dsp', 'update:platform', 'update:adtype', 'update:biddingType', 'onQuery'])
const props = defineProps({
    metadata: {
        type: Object,
        default: () => {}
    },
    updateDimension: {
        type: Object,
        default: () => {}
    }
});

let mtgModule = ref('madx');
const mtgModuleList = ref([
    {
        label: 'MADX',
        value: 'madx'
    },
    {
        label: 'SSP',
        value: 'ssp',
        disabled: true
    },
]);

let costType = ref(route.query.costType || 'lbcost');
const costTypeListMadx = ref([
    {
        label: 'LB成本',
        value: 'lbcost'
    },
    {
        label: '计算成本',
        value: 'cpucost'
    },
    {
        label: '出网成本',
        value: 'netoutcost'
    },
]);
const costTypeListSSP = ref([
    {
        label: 'LB成本',
        value: 'lbcost'
    },
    {
        label: '计算成本',
        value: 'cpucost'
    },
]);

let dateRes = ref(route.query.start ? { range: [(new Date(route.query.start)).getTime(), (new Date(route.query.end)).getTime()] } : { type: 'last7' });
let granularity = ref(route.query.granularity || 'day');

let cloudFilter = ref(route.query.cloud || 'all');
let cloudList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let regionFilter = ref(route.query.region || 'all');
let regionList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let sysFilter = ref(route.query.sys || 'all');
const sysList = [
    {
        label: '全部',
        value: 'all',
    },
    {
        label: 'ADX',
        value: 'adx',
    },{
        label: 'SDK',
        value: 'sdk',
    }
];

let adxFilter = ref(route.query.adx || 'all');
let adxList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let dspFilter = ref(route.query.dsp || 'all');
let dspList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let platFilter = ref(route.query.platform || 'all');
let platformList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let biddingTypeFilter = ref(route.query.bidding || 'all');
let biddingTypeList = ref([
    {
        label: '全部',
        value: 'all',
    }
]);

let regionObject = ref({});

function updateMtgModule() {
    costType.value = 'lbcost';
    updateCostType()
}
function disableDate(value) {
    return dayjs().subtract(2, 'day').valueOf() < value;
}

function onQueryChangeDate() {
    let dateRange = [dayjs(dateRes.value.range[0]).format('YYYY-MM-DD'), dayjs(dateRes.value.range[1]).format('YYYY-MM-DD')]
    emits('update:date', dateRange)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.start = dateRange[0];
    currentQuery.end = dateRange[1];
    router.replace({
        query: currentQuery,
    });
}

function onQueryChangeGranularity() {
    emits('update:granularity', granularity.value)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.granularity = granularity.value;
    router.replace({
        query: currentQuery,
    });
}

function updateCostType() {
    emits('update:costType', costType.value)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.costType = costType.value;
    router.replace({
        query: currentQuery,
    });
}

function updateCloudFilter() {
    regionList.value.splice(1, regionList.value.length - 1);
    regionList.value = [...regionList.value, ...regionObject.value[cloudFilter.value]];
    regionFilter.value = 'all';
    
    let val = {
        cloudFilter: cloudFilter.value,
        regionFilter: regionFilter.value,
    }
    emits('update:cloud', val)
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.cloud = cloudFilter.value;
    currentQuery.region = regionFilter.value;
    router.replace({
        query: currentQuery,
    });
};

function updateRegionFilter() {
    emits('update:region', regionFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.region = regionFilter.value;
    router.replace({
        query: currentQuery,
    });
};

function updateSysFilter() {
    emits('update:system', sysFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.sys = sysFilter.value;
    router.replace({
        query: currentQuery,
    });
}

function updateAdxFilter() {
    emits('update:adx', adxFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.adx = adxFilter.value;
    router.replace({
        query: currentQuery,
    });
}

function updateDspFilter() {
    emits('update:dsp', dspFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.dsp = dspFilter.value;
    router.replace({
        query: currentQuery,
    });
}

function updatePlatFilter() {
    emits('update:platform', platFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.platform = platFilter.value;
    router.replace({
        query: currentQuery,
    });
}

function updateBiddingFilter() {
    emits('update:biddingType', biddingTypeFilter.value);
    let currentQuery = {...router.currentRoute.value.query};
    currentQuery.bidding = biddingTypeFilter.value;
    router.replace({
        query: currentQuery,
    });
}

function onQueryResult() {
    emits('onQuery')
};

watch(() => props.updateDimension.type, (type) => {
        switch (type) {
            case 'cloud':
                cloudFilter.value = props.updateDimension.value.cloudFilter;
                regionFilter.value = props.updateDimension.value.regionFilter;
                break;
            case 'region':
                regionFilter.value = props.updateDimension.value
                break;
            case 'sys_type':
                sysFilter.value = props.updateDimension.value
                break;
            case 'adx':
                adxFilter.value = props.updateDimension.value
                break;
            case 'dsp':
                dspFilter.value = props.updateDimension.value
                break;
            case 'platform':
                platFilter.value = props.updateDimension.value
                break;
            case 'bidding_type':
                biddingTypeFilter.value = props.updateDimension.value
                break;
            default:
                break;
        }
    }
);

watch(() => props.metadata,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            let regionTotal = [] 
            for (let c in props.metadata.cloud) {
                cloudList.value.push({
                    label: c,
                    value: c
                });

                let temp = []
                for (let r in props.metadata.cloud[c]) {
                    temp.push({
                        label: props.metadata.cloud[c][r],
                        value: props.metadata.cloud[c][r]
                    })
                    regionTotal = [...new Set([...regionTotal, ...props.metadata.cloud[c]])];
                }
                regionObject.value[c] = temp;
            }

            let totaltemp = []
            for (const r in regionTotal) {
                totaltemp.push({
                    label: regionTotal[r],
                    value: regionTotal[r]
                });
            }
            regionObject.value['all'] = totaltemp;
            regionList.value = [...regionList.value, ...regionObject.value[cloudFilter.value]];

            for (const idx in props.metadata.adx) {
                adxList.value.push({
                    label: props.metadata.adx[idx],
                    value: props.metadata.adx[idx]
                });
            }

            for (const idx in props.metadata.dsp) {
                dspList.value.push({
                    label: props.metadata.dsp[idx],
                    value: props.metadata.dsp[idx]
                });
            }

            for (const idx in props.metadata.platform) {
                platformList.value.push({
                    label: props.metadata.platform[idx],
                    value: props.metadata.platform[idx]
                });
            }

            for (const idx in props.metadata.bidding_type) {
                biddingTypeList.value.push({
                    label: props.metadata.bidding_type[idx],
                    value: props.metadata.bidding_type[idx]
                });
            }

            if (props.metadata.bidding_type === undefined) {
                const temp = ['WF', 'HB']
                for (const idx in temp) {
                    biddingTypeList.value.push({
                        label: temp[idx],
                        value: temp[idx]
                    });
                }
            }
        }
    }
);
</script>