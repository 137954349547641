<template>
    <div class="content-all">
        <ChartTrend class="item-l" dimension="all" name="总成本趋势" :loading="props.all_loading" :chartData="props.all_data" @openDrawer="openDrawer" @showAlarmCreator="showAlarmCreator"></ChartTrend>
        <ChartPie class="item-s" name="总成本占比" :loading="props.all_loading" :chartData="props.all_data"></ChartPie> 
        <DetailDrawer v-model:show="showAnalysisDrawer" 
        :detail="drawerDetail"></DetailDrawer>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.chart-view {
    height: 30vw;
    .standard-border;
}
.content-all {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-l {
        width: 60%;
        margin-bottom: 10px;
        flex-grow: 1;

        &:nth-child(odd) {
            margin-right: 5px;
        }

        &:nth-child(2n) {
            margin-left: 5px;
        }
    }
    .item-s {
        width: 30%;
        margin-bottom: 10px;
        flex-grow: 1;

        &:nth-child(odd) {
            margin-right: 5px;
        }

        &:nth-child(2n) {
            margin-left: 5px;
        }
    }
}
</style>
<script setup>
import { ref,watch,defineProps,computed,defineEmits } from 'vue';
import ChartTrend from './ChartTrend.vue';
import ChartPie from './ChartPie.vue';
import DetailDrawer from './detail/DetailDrawer.vue';
import * as echarts from 'echarts/core';
import { BarChart,LineChart,PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
    GridComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
echarts.use([GridComponent, TooltipComponent, BarChart,LineChart,PieChart, CanvasRenderer, LegendComponent]);
import { NDrawer, NDrawerContent } from 'naive-ui';

const emits = defineEmits([ 'showAlarmCreator']);

const props = defineProps({
    all_data: {
        type: Object,
        default: () => {}
    },
    all_loading: {
        type: Boolean,
        default: true
    },
    costType: {
        type: String,
        default: 'lbcost'
    },
    start: {
        type: String,
        default: ''
    },
    end: {
        type: String,
        default: ''
    },
    reqObject: {
        type: Object,
        default: () => {}
    }
});

let showAnalysisDrawer = ref(false);
let drawerDetail = ref({});

function showAlarmCreator(val) {
    emits('showAlarmCreator', val);
};

function openDrawer(value) {
    drawerDetail.value = value;
    drawerDetail.value.params = props.reqObject;
    drawerDetail.value.type = props.costType;
    drawerDetail.value.name = value.cur.name;
    drawerDetail.value.start = props.start;
    drawerDetail.value.dim = value.dim;
    // 全部日期范围
    // drawerDetail.value.end = props.end;
    // 当前click point日期
    drawerDetail.value.end = value.cur.date;

    showAnalysisDrawer.value = true;
}
</script>