<template>
    <div class="content-dim">
        <ChartTrend
            class="item-l"
            :dimension="props.dimension"
            :name="chartName"
            :loading="loading"
            :chartData="data"
            @openDrawer="openDrawer"
            @updateDimension="updateDimension"
            @showAlarmCreator="showAlarmCreator"
        ></ChartTrend>
        <ChartPie
            class="item-s"
            :name="pieName"
            :loading="loading"
            :chartData="data"
        ></ChartPie>
        <DetailDrawer
            v-model:show="showAnalysisDrawer"
            :detail="drawerDetail"
        ></DetailDrawer>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.chart-view {
    height: 30vw;
    .standard-border;
}
.content-dim {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item-l {
        width: 60%;
        margin-bottom: 10px;
        flex-grow: 1;

        &:nth-child(odd) {
            margin-right: 5px;
        }

        &:nth-child(2n) {
            margin-left: 5px;
        }
    }
    .item-s {
        width: 30%;
        margin-bottom: 10px;
        flex-grow: 1;

        &:nth-child(odd) {
            margin-right: 5px;
        }

        &:nth-child(2n) {
            margin-left: 5px;
        }
    }
}
</style>
<script setup>
import { ref, defineProps, computed, onMounted, defineEmits } from 'vue';
import { useMessage } from 'naive-ui';
import ChartTrend from './ChartTrend.vue';
import ChartPie from './ChartPie.vue';
import DetailDrawer from './detail/DetailDrawer.vue';
import { CUSTOMIZATION_MADX_API } from '@/common/API';

const props = defineProps({
    cloud: {
        type: String,
    },
    region: {
        type: String,
    },
    granularity: {
        type: String,
    },
    dimension: {
        type: String,
    },
    sys: {
        type: String,
    },
    adx: {
        type: String,
    },
    dsp: {
        type: String,
    },
    platform: {
        type: String,
    },
    biddingType: {
        type: String,
    },
    adType: {
        type: String,
    },
    start: {
        type: String,
    },
    end: {
        type: String,
    },
    costType: {
        type: String,
    },
    report: {
        type: String,
    },
    name: {
        type: String,
        default: '',
    },
});

const emits = defineEmits(['loadByDimension', 'showAlarmCreator']);

const message = useMessage();

let showAnalysisDrawer = ref(false);
let drawerDetail = ref({});
let reqObject = ref({});
let loading = ref(false);
let data = ref({});

let chartName = computed(() => {
    return props.name + '成本趋势';
});
let pieName = computed(() => {
    return props.name + '成本占比';
});
function updateDimension(val) {
    emits('loadByDimension', val);
}
function showAlarmCreator(val) {
    emits('showAlarmCreator', val);
}
function load() {
    let params = {
        start_time: props.start,
        end_time: props.end,
        granularity: props.granularity,
        dimension: props.dimension,
        cloud: props.cloud,
        region: props.region,
        sys_type: props.sys,
        adx: props.adx,
        dsp: props.dsp,
        platform: props.platform,
        bidding_type: props.biddingType,
        adtype: props.adType,
    };
    reqObject.value = params;
    loading.value = true;
    CUSTOMIZATION_MADX_API.get_cost_share(props.report, props.costType, params)
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            data.value = res.data;
        })
        .catch(error => {
            console.log(error);
            data.value = {};
            loading.value = false;
        });
}
function openDrawer(value) {
    drawerDetail.value = value;
    drawerDetail.value.params = reqObject.value;
    drawerDetail.value.type = props.costType;
    drawerDetail.value.name = value.cur.name;
    drawerDetail.value.start = props.start;
    drawerDetail.value.dim = props.dimension;
    // 全部日期范围
    // drawerDetail.value.end = props.end;
    // 当前click point日期
    drawerDetail.value.end = value.cur.date;

    showAnalysisDrawer.value = true;
}

onMounted(() => {
    load();
});
</script>
